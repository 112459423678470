import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'


const cryptoApiHeaders = {
    'X-RapidAPI-Key': process.env.REACT_APP_RAPIDAPI_KEY,
    'X-RapidAPI-Host': 'coinranking1.p.rapidapi.com'
  }

const baseUrl = 'https://coinranking1.p.rapidapi.com'


const createRequest = (url) => ({ url, headers: cryptoApiHeaders }) //need headers and url to query request

//createApi comes from redux
export const cryptoApi = createApi({
    reducerPath: 'cryptoApi',
    baseQuery: fetchBaseQuery({ baseUrl }),
    endpoints: (builder) => ({
        getCryptos: builder.query({
            //count is being passed through from Cryptocurrencies component 
            query: (count) => createRequest(`/coins?limit=${count}`)
        }),
        getCryptoDetails: builder.query({
            query: (coinId) => createRequest(`/coin/${coinId}`) //we are looking on rapidApi for the endopoint that is needed
        }),
        getCryptoHistory: builder.query({
            query: ({ coinId, timeperiod }) => createRequest(`coin/${coinId}/history?timePeriod=${timeperiod}`) //we are looking on rapidApi for the endopoint that is needed
        })
    })
})

//redux toolkit creates a hook to grab all the data for the query. They also give you loading states and finalize states when making api calls
export const {
    useGetCryptosQuery,
    useGetCryptoDetailsQuery,              
    useGetCryptoHistoryQuery
} = cryptoApi
