import React from 'react'
import millify from 'millify' //format numbers
import { Typography, Row, Col, Statistic } from 'antd'
import { Link } from 'react-router-dom'

import { useGetCryptosQuery } from '../services/cryptoApi'
import Cryptocurrencies from './Cryptocurrencies'
import News from './News'
import Loader from './Loader'

const { Title } = Typography

//span; Raster number of cells to occupy, 0 corresponds to display: none
//There are 24 cells in ant design so 12 covers half

//Statistic component in ant design is to Display statistic number. Used when want to highlight some data or display stats with description

const Homepage = () => {

  
  const { data, isFetching } = useGetCryptosQuery(10)//setting the count to 10 so only 10 show up on the Homepage
  const globalData = data?.data?.stats

  console.log(data);

  if (isFetching) return <Loader/>




  return (
    <>
    <Title level={2} className='heading'>Global Crypto Stats</Title>
    <Row>
      <Col span={12}><Statistic title='Total Cryptocurrencies' value={globalData.total}/></Col>
      <Col span={12}><Statistic title='Total Exchanges' value={millify(globalData.totalExchanges)}/></Col>
      <Col span={12}><Statistic title='Total Market Cap' value={millify(globalData.totalMarketCap)}/></Col>
      <Col span={12}><Statistic title='Total 24h Volume' value={millify(globalData.total24hVolume)}/></Col>
      <Col span={12}><Statistic title='Total Markets' value={millify(globalData.totalMarkets)}/></Col>
    </Row>
    <div className="home-heading-container">
      <Title level={2} className='home-title'>Top 10 Cryptocurrencies in the world</Title>
      <Title level={3} className='show-more'><Link to='/cryptocurrencies'>Show More</Link></Title>
    </div>
    <Cryptocurrencies simplified/>
    <div className="home-heading-container">
      <Title level={2} className='home-title'>Latest Crypto News</Title>
      <Title level={3} className='show-more'><Link to='/news'>Show More</Link></Title>
    </div>
    <News simplified/>
    </>
  )
}

export default Homepage