import React from 'react'
import { Switch, Route, Link } from 'react-router-dom'
import { Layout, Typography, Space } from 'antd'
import { Navbar, Homepage, Exchanges, Cryptocurrencies, CryptoDetails, News } from './components'
import { BrowserRouter as Router } from 'react-router-dom' //we need to wrap code and imports in Router when using react-router-dom
import './App.css'


//Layout component is a component from antd that helps 
//Space is ant-design way of saying div


const App = () => {
  return (
    <Router>
      <div className="app">
        <div className="navbar">
          <Navbar />
        </div>
        <div className="main">
          <Layout>
            <div className="routes">
              <Switch>
                <Route exact path='/'><Homepage /></Route>
                <Route exact path='/exchanges'><Exchanges /></Route>
                <Route exact path='/cryptocurrencies'><Cryptocurrencies /></Route>
                <Route exact path='/crypto/:coinId'><CryptoDetails /></Route>
                <Route exact path='/news'><News /></Route>
              </Switch>
            </div>
          </Layout>

          <div className="footer" >
            <Typography.Title level={5} style={{ color: 'white', textAlign: 'center' }}>
              Cryptonium <br />
              All rights reserved
            </Typography.Title>
            <Space>
              <Link to='/home'>Home</Link>
              <Link to='/exchanges'>Exchanges</Link>
              <Link to='/news'>News</Link>
            </Space>
          </div>
        </div>
      </div>
    </Router>
  )
}

export default App
