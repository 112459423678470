import React, { useState, useEffect } from 'react'
import { Button, Menu, Typography, Avatar } from 'antd'
import { Link } from 'react-router-dom'
import { HomeOutlined, MoneyCollectOutlined, BulbOutlined, FundOutlined, MenuOutlined } from '@ant-design/icons'
import icon from '../images/cryptocurrency-2.png'

const Navbar = () => {

    const [activeMenu, setActiveMenu] = useState(true)
    const [screenSize, setScreenSize] = useState(null)


    useEffect(() => {
        const handleResize = () => { setScreenSize(window.innerwidth) } //window.innerwidth is how we can get the screen size

        window.addEventListener('resize', handleResize) //we are adding anm event listener run the handleResize function everytime the window size changes

        handleResize() //calling function set the screen size

        return () => window.removeEventListener('resize', handleResize) // removes the event listener for resize

    }, [])


    //this useEffect is called whenever the screen size changes, which will show the active menu if the screen size is less than 768 pixels
    useEffect(() => {
        if (screenSize < 768) {
            setActiveMenu(false)
        }
        else {
            setActiveMenu(true)
        }
    }, [screenSize])



    return (
        <div className="nav-container">
            <div className="logo-container">
                <Avatar src={icon} size='large' />
                <Typography.Title level={2} className='logo'>
                    <Link to='/'>Cryptonium</Link>
                </Typography.Title>

                {/* need to set onClick to what the opposite of what activeMenu is to toggle */}
                <Button className='menu-control-container' onClick={() => setActiveMenu(!activeMenu)}>
                    <MenuOutlined/>
                </Button>
            </div>


            {activeMenu && (
                <Menu theme='dark'>
                    <Menu.Item icon={<HomeOutlined />}>
                        <Link to='/'>Home</Link>
                    </Menu.Item>
                    <Menu.Item icon={<FundOutlined />}>
                        <Link to='/cryptocurrencies'>Cryptocurrencies</Link>
                    </Menu.Item>
                    {/* <Menu.Item icon={<MoneyCollectOutlined />}>
                        <Link to='/exchanges'>Exchanges</Link>
                    </Menu.Item> */}
                    <Menu.Item icon={<BulbOutlined />}>
                        <Link to='/news'>News</Link>
                    </Menu.Item>
                </Menu>
            )}
        </div>
    )
}

export default Navbar