import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
// import 'antd/dist/antd.css'; //need to revert back to version 4 in order to import
import { Provider } from 'react-redux';
import 'antd/dist/antd.min.css'

import store from './app/store';

//wrapping the App component with Provider so that everything in the app component has access to the store 

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);


